import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import DropdownButton from './DropdownButton';

const GenerateDropdown = () => {
    const [dropdownData, setDropdownData] = useState([]);
    const [checking, setChecking] = useState(false);
    const [setSelectedSubCategory] = useState('');

    const fetchData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/fetch-dropdowns`);
            const data = await response.json();
            setDropdownData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Main>
            {dropdownData.length > 0 ? (
                dropdownData.map((x, key) => (
                    <DropdownButton
                        key={key}
                        title={x.title}
                        data={x.options}
                        checking={checking}
                        setChecking={setChecking}
                        subCategory={x.options.map(option => option.subCategory)}
                        type={x.type}
                        setSelectedSubCategory={setSelectedSubCategory}
                    />
                ))
            ) : (
                <p>No dropdown data available</p>
            )}
        </Main>
    );
};

const Main = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export default GenerateDropdown;