import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import Navbar from "../components/Navbar";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
`;

const ImageGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    width: 100%;
    padding: 1rem;
`;

const ImageItem = styled.div`
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 2px 2px 6px 2px gray;

    img {
        width: 100%;
        height: auto;
        display: block;
    }
`;

const Dropdown = styled.select`
    margin: 1rem;
    padding: 0.5rem;
    font-size: 1rem;
`;

const LibraryPage = () => {
    const [images, setImages] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [category, setCategory] = useState('all');
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef();
    const sentinel = useRef(null);

    useEffect(() => {
        setUsername('admin');
    }, []);

    useEffect(() => {
        if (hasMore) {
            fetchImages(page, category);
        }
    }, [page, category, hasMore]);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0
        };

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && !loading && hasMore) {
                setPage((prevPage) => prevPage + 1);
            }
        }, options);

        const currentSentinel = sentinel.current;
        if (observer.current && currentSentinel) {
            observer.current.observe(currentSentinel);
        }

        return () => {
            if (observer.current && currentSentinel) {
                observer.current.unobserve(currentSentinel);
            }
        };
    }, [loading, hasMore]);

    const fetchImages = async (page, category) => {
        setLoading(true);
        try {
            let categories = category === 'all' ? ['anime', 'typography', 'illustrations'] : [category];
            let allImages = [];

            for (let cat of categories) {
                const response = await fetch(`${process.env.REACT_APP_URL}/api/forward/images?page=${page}&path=${cat}`);
                const data = await response.json();
                allImages = [...allImages, ...data.images];
            }

            if (allImages.length === 0) {
                setHasMore(false);
            } else {
                setImages((prevImages) => page === 0 ? allImages : [...prevImages, ...allImages]);
            }
        } catch (error) {
            console.error('Error fetching images:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
        setPage(0);
        setImages([]);
        setHasMore(true);
    };


    if (username !== 'admin' && username !== 'cosmic coder') {
        return <p>Access Denied</p>;
    }

    return (
        <Container>
            <Navbar/>
            <UpperInput>
                <h1>Library !!</h1>
                <Dropdown value={category} onChange={handleCategoryChange}>
                    <option value="all">All</option>
                    <option value="anime">Anime</option>
                    <option value="typography">Typography</option>
                    <option value="illustrations">Illustration</option>
                </Dropdown>
            </UpperInput>
            <ImageGrid>
                {images.map((image, index) => (
                    <ImageItem key={index}>
                        <img src={image} alt={"notfound"}/>
                    </ImageItem>
                ))}
                <div ref={sentinel} style={{height: '1px'}}></div>
            </ImageGrid>
            {loading && <p>Loading...</p>}
        </Container>
    );
};

const UpperInput = styled.div`
    margin-top: 7rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;

    h1 {
        font-weight: 700;
        font-size: 44px;
        text-align: center;
        background: #5F5CE8;
        background: linear-gradient(to right, #5F5CE8 0%, #221A1A 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 2rem;
    }

    button {
        padding: 0.5rem 1rem;
        font-size: 1rem;
        cursor: pointer;
        background-color: #5F5CE8;
        color: white;
        border: none;
        border-radius: 5px;
    }
`;

export default LibraryPage;