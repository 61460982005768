import React, {useEffect, useRef, useState} from 'react';
import Navbar from '../components/Navbar';
import styled from 'styled-components';
import GenerateDropdown from '../components/generate/GenerateDropdown';
import {generateOperationContext} from '../context/context';
import MockUpCard from '../components/generate/MockUpCard';
import loading from '../utils/loading.gif';
import FullScreenComp from '../components/generate/FullScreenComp';
import background from '../utils/backgrounds/5.jpg';

export default function Generate() {
    const [showDownloadOptions, setShowDownloadOptions] = useState(false);
    const [progress, setProgress] = useState(0);
    const [isGenerating, setIsGenerating] = useState(false);
    const [mockUpDailougeBox, setMockUpDailougeBox] = useState(false);
    const [currOpenKey, setCurrOpenKey] = useState(-1);
    const [imageMetaData, setImageMetaData] = useState();
    const [reqMetaData, setReqMetaData] = useState();
    const [mockUpData, setMockUpData] = useState();
    const url1 = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtSdMFwHf_YkpMfc-UwyeByhsLhqOwWJ0SLQ&s';
    const url3 = 'https://i.pinimg.com/736x/2d/3a/1d/2d3a1dc45a181bc9772756b7ffe5a2d8.jpg';
    const url4 = 'https://i.pinimg.com/736x/62/62/49/626249b84409e2d4e9168188897c3608.jpg';
    const [suggestions, setSuggestions] = useState([]);
    const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState(0);

    const [dummy, setDummy] = useState([{id: 0, url: url1}, {id: 1, url: url3}, {id: 2, url: url4},]);

    const [generateOptions, setGenerateOptions] = useState({
        category: '', platform: [], numbers: '', inspiration: '', subCategory: '', Prompts: [],
    });

    const imageRef = useRef(null);
    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setMockUpDailougeBox(false);
            setCurrOpenKey(-1);
        }
        // DownloadOptions display yo none

        setShowDownloadOptions(false);


    };
    useEffect(() => {
        const fetchSuggestions = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_URL}/get-suggestions`);
                const data = await response.json();
                setSuggestions(data);
            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        };

        fetchSuggestions();
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Tab') {
                event.preventDefault(); // Prevent the default tab behavior
                const inputElement = event.target;
                if (inputElement.placeholder) {
                    const cleanedPlaceholder = inputElement.placeholder.replace("✨", "").trim();
                    inputElement.value = cleanedPlaceholder;
                    setGenerateOptions({...generateOptions, inspiration: cleanedPlaceholder});
                }
            }
        };

        const inputElement = document.querySelector('input[type="text"]');
        inputElement.addEventListener('keydown', handleKeyDown);

        return () => {
            inputElement.removeEventListener('keydown', handleKeyDown);
        };
    }, [suggestions, currentSuggestionIndex, generateOptions]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSuggestionIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
        }, 4000);

        return () => clearInterval(interval);
    }, [suggestions]);

    const handleGenerate = async () => {
        setIsGenerating(true);
        setProgress(0);

        setDummy([{id: 0, url: loading}]);
        try {
            const totalImages = generateOptions.numbers ? parseInt(generateOptions.numbers, 10) : 1;
            const totalTime = totalImages * 12 * 1000; // Total time in milliseconds
            const intervalTime = 500; // Update progress every second
            const maxProgress = 99; // Max progress percentage
            const totalIntervals = (totalTime / intervalTime) * (maxProgress / 100);

            let apiCallCompleted = false;

            const progressUpdate = async () => {
                for (let i = 0; i <= totalIntervals; i++) {
                    if (apiCallCompleted) {
                        setProgress(100);
                        break;
                    }
                    setProgress(Math.round((i / totalIntervals) * maxProgress));
                    await new Promise((resolve) => setTimeout(resolve, intervalTime));
                }
            };

            const apiCall = async () => {
                const mp = generateOptions.platform.map((x) => x.toLocaleLowerCase());

                const res = await fetch(`${process.env.REACT_APP_URL}/generate-by-category`, {
                    method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({
                        subCategory: generateOptions.subCategory ? generateOptions.subCategory : 'DragonBall',
                        category: generateOptions.category ? generateOptions.category.toLocaleLowerCase() : 'Anime',
                        numberOfImages: totalImages,
                        source: generateOptions.platform ? mp : ['pinterest'],
                        prompt: generateOptions.inspiration ? generateOptions.inspiration : 'Generate any character from (dbSuperStyle)Anime',
                    }),
                });

                const data = await res.json();
                setImageMetaData(data.images);

                if (data && data.images && Array.isArray(data.images)) {
                    const imageUrls = data.images.map((base64String, index) => {
                        try {
                            return decodeBase64ToImageSrc(base64String);
                        } catch (decodeError) {
                            console.error(`Error decoding image ${index + 1}:`, decodeError);
                            return null;
                        }
                    }).filter(Boolean);

                    imageUrls.forEach((imageUrl, index) => {
                        setDummy((oldDummy) => [...oldDummy, {id: index + 1, url: imageUrl}]);
                    });
                }

                apiCallCompleted = true;
            };

            await Promise.all([progressUpdate(), apiCall()]);

            if (!apiCallCompleted) {
                setProgress(100);
            }
        } catch (error) {
            console.log('An error occurred:', error);
        } finally {
            setIsGenerating(false);
        }
    };

    const handleMockUpLink = (idAndUrl) => {
        console.log('data for conversion in mockups => ', dummy);
        console.log('meta data for conversion in mockups => ', imageMetaData);

        if (idAndUrl) {
            setMockUpData([]);

            if (imageMetaData) {
                setReqMetaData(imageMetaData[idAndUrl.id - 1]);
            } else {
                setReqMetaData(dummy[idAndUrl.id].url);
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        const createMockup = async () => {
            if (reqMetaData) {
                console.log('data set for sending => ', reqMetaData);

                const res = await fetch(`${process.env.REACT_APP_URL}/createTshirtMockup`, {
                    method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({
                        metaData: reqMetaData,
                    }),
                });

                const resData = await res.json();
                console.log('response Data => ', resData.merged_image);
                setMockUpData(resData.merged_image);
            }
        };

        createMockup();
    }, [reqMetaData]);

    const decodeBase64ToImageSrc = (base64String, mimeType = 'image/png') => {
        return `data:${mimeType};base64,${base64String}`;
    };
    const handleDownload = async (url, downloadFormat) => {
        if (downloadFormat === 'png') {
            const link = document.createElement('a');
            link.href = url;
            link.download = 'image.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else if (downloadFormat === 'svg') {
            try {
                const base64Data = url.replace(/^data:image\/png;base64,/, '');
                const response = await fetch(`${process.env.REACT_APP_URL}/convert-png-to-svg`, {
                    method: 'POST', headers: {
                        'Content-Type': 'application/json',
                    }, body: JSON.stringify({base64_png: base64Data}),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                const base64Svg = data.base64_svg;

                const link = document.createElement('a');
                link.href = `data:image/svg+xml;base64,${base64Svg}`;
                link.download = 'image.svg';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.error('Error downloading the image:', error);
            }
        }
    };


    return (<generateOperationContext.Provider value={{generateOptions, setGenerateOptions}}>
        <Contain>
            background-image: url(${background}); // Set the background image
            background-size: cover; // Ensure the background covers the entire container
            background-position: center;
            <Navbar/>
            <Main>
                <UpperInput>
                    <h1>Design Inspirations made Simple !!</h1>
                    <InputContainer>
                        <input
                            type="text"
                            placeholder={suggestions.length > 0 ? suggestions[currentSuggestionIndex] : "✨  What inspiration do you want to generate?"}
                            onChange={(e) => {
                                setGenerateOptions({...generateOptions, inspiration: e.target.value});
                            }}
                        />
                        <GenerateButton onClick={handleGenerate}>
                            {isGenerating ? `Generating... ${progress}%` : 'Generate Now'}
                        </GenerateButton>
                    </InputContainer>
                </UpperInput>

                <OptionDropDown>
                    <GenerateDropdown/>
                </OptionDropDown>


                <ResultSection>
                    <OutputSection>
                        {dummy.map((x, key) => key >= 1 || dummy.length === 1 ? (<ImageFramer key={key}>
                            <img ref={imageRef} src={x.url} alt="results images"/>
                            <ActionButtons>
                                <SVGlike
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M2 21H6V9H2V21ZM23 10.9999C23 9.89995 22.1 8.99995 21 8.99995H14.72L15.64 4.63995L15.66 4.33995C15.66 3.74995 15.39 3.19995 14.96 2.84995L14.28 2.27995L7.6 8.53995C7.22 8.87995 7 9.40995 7 9.99995V18.9999C7 20.1 7.9 20.9999 9 20.9999H18C18.71 20.9999 19.31 20.6299 19.66 20.0699L22.86 14.4799C22.95 14.3299 23 14.1599 23 13.9999V10.9999Z"
                                        fill="black"
                                    />
                                </SVGlike>

                                <SVGdislike
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M22 3H18V15H22V3ZM2 14.9999C2 16.0999 2.9 16.9999 4 16.9999H10.28L9.36 21.3599L9.34 21.6599C9.34 22.2499 9.61 22.7999 10.04 23.1499L10.72 23.7199L17.4 17.4599C17.78 17.1199 18 16.5899 18 15.9999V6.99995C18 5.89995 17.1 4.99995 16 4.99995H7C6.29 4.99995 5.69 5.36995 5.34 5.92995L2.14 11.5199C2.05 11.6699 2 11.8399 2 11.9999V14.9999Z"
                                        fill="black"
                                    />
                                </SVGdislike>

                                <SVGdownload
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={() => setShowDownloadOptions(!showDownloadOptions)}
                                >
                                    <path
                                        d="M5 20H19V18H5V20ZM12 3L12 14M12 14L8 10M12 14L16 10"
                                        stroke="black"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </SVGdownload>


                                <button
                                    onClick={(e) => {
                                        if (key !== currOpenKey && currOpenKey !== -1) {
                                        } else if (mockUpDailougeBox === false) {
                                            setMockUpDailougeBox(true);
                                            setCurrOpenKey(key);
                                        } else {
                                            setCurrOpenKey(-1);
                                            setMockUpDailougeBox(false);
                                        }
                                        handleMockUpLink(x);
                                    }}
                                >
                                    Mockups
                                </button>


                            </ActionButtons>
                            <DownloadOptions show={showDownloadOptions}>
                                <DownloadButton onClick={() => {
                                    handleDownload(x.url, 'png');
                                }} style={{marginRight: '10px'}}>PNG</DownloadButton>
                                <DownloadButton onClick={() => {
                                    handleDownload(x.url, 'svg');
                                }}>SVG</DownloadButton>
                            </DownloadOptions>

                            <FullScreenComp url={x.url}/>

                            {mockUpDailougeBox === true && currOpenKey === key ? (
                                <MockUpBox ref={dropdownRef} key={key}>
                                    <svg
                                        onClick={(e) => {
                                            if (key !== currOpenKey && currOpenKey !== -1) {
                                            } else if (mockUpDailougeBox === false) {
                                                setMockUpDailougeBox(true);
                                                setCurrOpenKey(key);
                                            } else {
                                                setCurrOpenKey(-1);
                                                setMockUpDailougeBox(false);
                                            }
                                        }}
                                        style={{transform: 'rotate(45deg)'}}
                                        width="100"
                                        height="100"
                                        viewBox="0 0 100 100"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line x1="10" y1="50" x2="90" y2="50" stroke="black"
                                              strokeWidth="10"/>
                                        <line x1="50" y1="10" x2="50" y2="90" stroke="black"
                                              strokeWidth="10"/>
                                    </svg>
                                    <h3>Test Mockup</h3>
                                    <CardSection>
                                        {mockUpData?.map((mockUpInfo, key) => (<MockUpCard
                                            key={key}
                                            url={decodeBase64ToImageSrc(mockUpInfo.metaData) ? decodeBase64ToImageSrc(mockUpInfo.metaData) : x.url}
                                        />))}
                                    </CardSection>
                                </MockUpBox>) : (<></>)}
                        </ImageFramer>) : (<></>))}
                    </OutputSection>
                </ResultSection>
            </Main>
        </Contain>
    </generateOperationContext.Provider>);
}

// styling part is present here
// const Contain = styled.div`
//     display: flex;
//     flex-direction: column;
// `;
const Main = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
`;
const UpperInput = styled.div`
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    h1 {
        font-weight: 700;
        font-size: 44px;
        text-align: center;
        background: #5F5CE8;
        background: linear-gradient(to right, #5F5CE8 0%, #221A1A 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 2rem;
    }
`;

const InputContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-left: 5rem;

    input {
        color: black;
        font-weight: 600;
        min-width: 50vw;
        min-height: 6vh;
        border: 1px solid;
        padding: 0.3rem 1rem;
        border-radius: 28px;
        @media (max-width: 768px) {
            min-width: 70vw;
        }
    }
`;

const GenerateButton = styled.button`
    cursor: pointer;
    border: 1px solid black;
    background-color: #3ebfee;
    font-weight: 550;
    min-width: 10rem;
    min-height: 3rem;
    padding: 0.3rem 1rem;
    font-size: 18px;
    border-radius: 5px;

    transition: 0.3s ease;

    &:hover {
        background-color: #2f46b7;
        color: white;
        border: none;
    }
`;


const OptionDropDown = styled.div`
    margin-bottom: 0rem;
    z-index: 3;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 25px;
`;

const ResultSection = styled.div`
    margin-top: 1rem;
`;

const OutputSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px;

    @media (max-width: 768px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
`;


const ActionButtons = styled.div`
    width: 100%;
    position: absolute;
    bottom: 0;
    margin-bottom: 1rem;
    display: none; /* Initially hidden */
    justify-content: space-around;
    align-items: center;
    padding: 0.5rem;
    //background-color: rgba(255, 255, 255, 0.8);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: 0.2s ease-out;

    svg, button {
        cursor: pointer;
        transition: transform 0.2s ease-in-out;
    }

    svg:hover, button:hover {
        transform: scale(1.1);
    }

    button {
        background-color: #3ebfee;
        color: white;
        font-size: 14px;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        border: none;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #2f46b7;
        }
    }
`;

const FullScreen = styled.div`
    position: absolute;
    top: 4px;
    right: 0.8rem;
    display: none;
    cursor: pointer;
    transition: transform 0.1s ease-in-out;

    &:hover {
        transform: scale(1.5);
        color: blue; /* Change to blue on hover */
    }

    svg {
        transition: fill 0.3s ease-in;
    }

    &:hover svg path {
        fill: blue; /* Change to blue on hover */
    }
`;

const ImageFramer = styled.div`
    cursor: pointer;
    position: relative;
    width: 20vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 15px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 5px 5px 10px 5px gray;

    @media (max-width: 768px) {
        min-width: 45vw;
        min-height: 40vh;
    }

    img {
        width: 90%;
        margin: 5%;
        border-radius: 10px;
        box-shadow: 2px 2px 6px 2px gray;

        @media (max-width: 768px) {
            width: 70%;
        }

    }

    &:hover ${ActionButtons} {
        display: flex;
    }

    &:hover ${FullScreen} {
        display: flex;
    }
`;


const MockUpBox = styled.div`
    box-shadow: 1px 1px 1px 4px gray;
    z-index: 4;
    min-width: 33vw;
    min-height: 60vh;
    border-radius: 10px;
    padding: 2%;
    background-color: white;
    position: absolute;
    @media (max-width: 768px) {
        min-width: 60vw;
        min-height: 35vh;
    }

    svg {
        float: right;
        transition: 0.1s ease-in;
        height: 4vh;
        width: 2vw;

        &:hover {
            background-color: red;
            border-radius: 50%;
        }
    }

    h3 {
        font-weight: 600;
        text-align: center;
    }
`;
const CardSection = styled.div`
    min-height: 60vh;
    box-shadow: 5px 5px 10px 5px gray;
    margin-top: 10px;
    border-radius: 10px;
    background-color: lightblue;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;


const SVGlike = styled.svg`
    transition: 0.3s ease-in;

    &:hover {
        border-radius: 50%;
        color: limegreen; /* Change to green on hover */
        height: 3rem;
        width: 3rem;
    }

    path {
        transition: fill 0.3s ease-in;
    }

    &:hover path {
        fill: limegreen; /* Change to green on hover */
    }
`;

const SVGdislike = styled.svg`
    transition: 0.3s ease-in;

    &:hover {
        border-radius: 50%;
        color: red; /* Change to red on hover */
        height: 3rem;
        width: 3rem;
    }

    path {
        transition: fill 0.3s ease-in;
    }

    &:hover path {
        fill: red; /* Change to red on hover */
    }
`;

const SVGdownload = styled.svg`
    transition: 0.3s ease-in;

    &:hover {
        border-radius: 50%;
        color: lightgreen; /* Change to light green on hover */
        height: 3rem;
        width: 3rem;
    }

    path {
        transition: fill 0.3s ease-in;
    }

    &:hover path {
        fill: lightgreen; /* Change to light green on hover */
    }
`;

const DownloadOptions = styled.div`
    position: absolute;
    top: 50%;
    right: 20%;
    bottom: 20rem;
    border-radius: 5px;
    padding: 10px;
    visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition: visibility 0s, opacity 0.3s linear;
        //display: ${(props) => (props.show ? 'block' : 'none')};
`;

const DownloadButton = styled.button`
    cursor: pointer;
    border: none;
    background-color: #3ebfee;
    font-weight: 550;
    padding: 0.3rem 1rem;
    font-size: 18px;
    border-radius: 5px;
    transition: 0.3s ease;

    &:hover {
        background-color: #2f46b7;
        color: white;
    }
`;

const Contain = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    
`;

