import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { generateOperationContext } from '../../context/context';
import SubCategoryDropdown from './SubCategoryDropdown';

export default function DropdownButton({ title, data, type }) {
    const value = useContext(generateOperationContext);
    const [open, setOpen] = useState(false);
    const [openSubCategory, setOpenSubCategory] = useState(null);
    const [item, setItem] = useState(title);
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const dropdownRef = useRef(null);

    const handleOpen = () => {
        setOpen(!open);
        setOpenSubCategory(null); // Close any open subcategory when main dropdown is toggled
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpen(false);
            setOpenSubCategory(null);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const toggleSubCategory = (index) => {
        setOpenSubCategory((prev) => (prev === index ? null : index));
    };

    const handleSubCategorySelect = (subCategoryName) => {
        setSelectedSubCategory(subCategoryName);
        setOpen(false);
        setOpenSubCategory(null);
        value.setGenerateOptions({ ...value.generateOptions, subCategory: subCategoryName });
    };

    return (
        <Dropdown ref={dropdownRef}>
            <DropdownSelection>
                <Dropdownbutton onClick={handleOpen}>
                    {title === 'Platform Name'
                        ? value.generateOptions.platform.length === 0
                            ? 'Sources'
                            : value.generateOptions.platform.map((x, key) => (
                                <React.Fragment key={key}>
                                    {key === 0 ? (
                                        <>{x}</>
                                    ) : (
                                        <>, {x}</>
                                    )}
                                </React.Fragment>
                            ))
                        : `${item}${selectedSubCategory ? ` ` : ''}`}
                    {value.generateOptions.subCategory && type === 'Category' ? (
                        <>&nbsp;({value.generateOptions.subCategory})</>
                    ) : null}
                </Dropdownbutton>
                {open ? (
                    <svg
                        onClick={handleOpen}
                        style={{ transition: '0.2s ease-in', transform: 'rotate(180deg)', transformOrigin: 'center' }}
                        color="white"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M7 10L12 15L17 10H7Z" fill="currentColor" />
                    </svg>
                ) : (
                    <svg
                        onClick={handleOpen}
                        style={{ transition: '0.2s ease-in' }}
                        color="white"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M7 10L12 15L17 10H7Z" fill="currentColor" />
                    </svg>
                )}
            </DropdownSelection>

            {open && (
                <DropdownContainer>
                    {data.map((option, key) => (
                        <DropdownItem
                            key={key}
                            onClick={() => {
                                setItem(option.name);
                                toggleSubCategory(key);
                                if (type === 'Category') {
                                    value.setGenerateOptions({ ...value.generateOptions, category: option.name });
                                } else {
                                    value.setGenerateOptions({ ...value.generateOptions, numbers: option.name });
                                }
                            }}
                        >
                            {option.name}
                            {option.subCategory && type === 'Category' && openSubCategory === key ? (
                                <svg
                                    onClick={() => toggleSubCategory(key)}
                                    style={{ transition: '0.2s ease-in', transform: 'rotate(180deg)', transformOrigin: 'center' }}
                                    color="white"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M7 10L12 15L17 10H7Z" fill="black" />
                                </svg>
                            ) : (
                                type === 'Category' &&
                                openSubCategory !== key && (
                                    <svg
                                        style={{ transition: '0.2s ease-in' }}
                                        color="white"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M7 10L12 15L17 10H7Z" fill="currentColor" />
                                    </svg>
                                )
                            )}
                        </DropdownItem>
                    ))}
                    {data
                        .filter((option, key) => option.subCategory && type === 'Category' && openSubCategory === key)
                        .map((option, key) => (
                            <SubCategoryDropdown
                                key={key}
                                subCategories={option.subCategory}
                                openSubCategory={openSubCategory === key}
                                setOpenSubCategory={() => toggleSubCategory(key)}
                                open={open}
                                setOpen={setOpen}
                                handleSubCategorySelect={handleSubCategorySelect}
                            />
                        ))}
                </DropdownContainer>
            )}
        </Dropdown>
    );
}

const Dropdown = styled.div`
    background-color: #fff;
    @media (max-width: 768px) {
        min-height: 25vh;
    }
`;
const DropdownSelection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    position: relative;

    svg {
        cursor: pointer;
        position: absolute;
        right: 20px;
    }
`;

const Dropdownbutton = styled.button`
    min-width: 12vw;
    cursor: pointer;
    border: none;
    background-color: black;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 0.5rem 1rem;
    padding-right: 2.5rem; /* Add padding to the right to create space for the arrow */
    border-radius: 15px;
    @media (max-width: 1000px) {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 55vw;
    }
`;
const DropdownContainer = styled.div`
    background-color: white;
    margin-left: 1rem;
    z-index: 3;
    position: absolute;
    min-width: 12vw;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 1px 1px 1px 4px gray;
    padding: 0.4rem 1rem;
    @media (max-width: 1000px) {
        position: relative;
        min-width: 15vw;
    }
`;
const DropdownItem = styled.div`
    transition: 0.3s ease-in;
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding-left: 0.2rem;

    &:hover {
        background-color: #7894e6;
        border-radius: 4px;
    }
`;