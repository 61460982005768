// Navbar.jsx
import React, {useContext, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import logo from '../utils/logo.png'
import {useNavigate} from "react-router-dom";
import {userContext} from '../context/context';
import StockAvtar from '../utils/StockAvtar.jpg'


const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const Navigate = useNavigate();
    const userValue = useContext(userContext)


    const handleLogout = () => {
        userValue.setUser('');
        sessionStorage.clear();
        localStorage.clear();
        Navigate('/')
    }


    const [profileDetailMenu, setProfileDetailMenu] = useState(false);
    const toggleProfileDetails = () => {
        setProfileDetailMenu(!profileDetailMenu)
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const dropdownRef = useRef(null);
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setProfileDetailMenu(false);
        }

    };


    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };

    }, []);

    return (<Nav>
        <Logo onClick={e => {
            Navigate('/')
        }}><img src={logo} alt="logo is present here"/></Logo>
        <Burger isOpen={isOpen} onClick={toggleMenu}>
            <span/>
            <span/>
            <span/>
        </Burger>
        <Menu isOpen={isOpen}>
            {/* <MenuItem>Pricing</MenuItem>
        <MenuItem>Blog</MenuItem> */}
            {userValue.user.userName ? <MenuItem onClick={e => {
                Navigate('/generate')
            }}>Generate Designs</MenuItem> : <></>}
            {userValue.user.userName ? <MenuItem onClick={e => {
                Navigate('/contactus')
            }}>Contact Us</MenuItem> : <></>}
            {userValue.user.userName ? <MenuItem onClick={e => {
                Navigate('/mockup-upload')
            }}>Mockups</MenuItem> : <></>}
            {userValue.user.userName ? <MenuItem onClick={e => {
                Navigate('/library')
            }}>Library</MenuItem> : <></>}
            {(/admin|cosmic coder|harshit|abhishek/i.test(userValue.user.userName)) && (
                <MenuItem onClick={e => {
                    Navigate('/image-history')
                }}>Image History</MenuItem>
            )}

            <MenuItem>
                <Dynamic>  {userValue.user.userName ? <BurgerAuthItem>
                    <p>Hi! {userValue.user.userName}</p>
                    <img src={userValue.user.userImage ? userValue.user.userImage : StockAvtar}
                         alt="Profile"/>
                    <button onClick={handleLogout}>Log Out</button>
                </BurgerAuthItem> : <AuthButtons><p onClick={e => {
                    Navigate('/signup')
                }}>Take a Try</p><p onClick={e => {
                    Navigate('/contactus')
                }}>Contact Us</p></AuthButtons>}  </Dynamic>
            </MenuItem>
        </Menu>

        {userValue.user.userName ? <ProfileDetails>
            <ProfilePic onClick={toggleProfileDetails}
                        src={userValue.user.userImage ? userValue.user.userImage : StockAvtar}
                        alt="Profile"/>
            {profileDetailMenu === false ? <></> : <ProfileView ref={dropdownRef} onClick={toggleProfileDetails}>
                <p>Hello! {userValue.user.userName}</p>
                <button onClick={handleLogout}>Log Out</button>
            </ProfileView>}
        </ProfileDetails> : <Buttons>
            <Login onClick={e => {
                Navigate('/signup')
            }}>Take a Try</Login>
            <ContactUs onClick={e => {
                Navigate('/contactus')
            }}>Contact Us</ContactUs>
        </Buttons>

        }
    </Nav>);
};

export default Navbar;

// Styled Components
const Nav = styled.nav`
    z-index: 5;
    min-width: 100vw;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    transition: 0.3s ease-out;
    background-color: rgba(243, 247, 248, 0.6);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);

    &:hover {
        background-color: #d3d3d3;
    }

    height: 4rem;
`;

const Logo = styled.div`
    cursor: pointer;

    img {
        height: 8rem;
    }
`;

const Burger = styled.div`
    display: none;
    cursor: pointer;

    span {
        display: block;
        width: 25px;
        height: 3px;
        margin: 5px;
        background: black;
        transition: 0.3s;

        &:nth-child(1) {
            transform: ${({isOpen}) => (isOpen ? 'rotate(45deg) translate(5px, 5px)' : 'rotate(0)')};
        }

        &:nth-child(2) {
            opacity: ${({isOpen}) => (isOpen ? '0' : '1')};
        }

        &:nth-child(3) {
            transform: ${({isOpen}) => (isOpen ? 'rotate(-45deg) translate(5px, -5px)' : 'rotate(0)')};
        }
    }

    @media (max-width: 768px) {
        display: block;
    }
`;
const Menu = styled.div`
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    @media (min-width: 768px) {
        background: -webkit-linear-gradient(#000, #666);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    @media (max-width: 768px) {
        display: ${({isOpen}) => (isOpen ? 'block' : 'none')};
        position: absolute;
        top: 62px;
        left: 0;
        width: 100%;
        color: #333;
        background: #d3d3d3;
        text-align: center;
    }
`;

const MenuItem = styled.a`
    text-align: center;
    margin: 0 1rem;
    cursor: pointer;

    @media (max-width: 768px) {
        display: block;
        margin: 1rem 0;
    }
`;

const ProfilePic = styled.img`
    cursor: pointer;
    margin-right: 2rem;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 1rem;
    @media (max-width: 768px) {
        display: none;
    }
`;
const Buttons = styled.div`
    min-width: 10vw;
    padding: 0.3rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    @media (max-width: 768px) {
        display: none;
    }
`;
const Login = styled.button`
    padding: 0.2rem 1rem;
    color: #000;
    border: 0.3px solid #000;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    transition: 0.3s ease-in;

    &:hover {
        color: white;
        background-color: #000;
    }
`;
const ContactUs = styled.button`
    padding: 0.2rem 1rem;
    background-color: #000;
    color: white;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    transition: 0.3s ease-in;

    &:hover {
        color: #000;
        background-color: white;
    }
`;

const Dynamic = styled.div`
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
`;

const AuthButtons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    p {
        cursor: pointer;

        &:hover {
            color: white;
            background-color: #14b7f2;
            padding: 0.1rem 1rem;
            border-radius: 10px;
        }
    }
`;

const ProfileDetails = styled.div`
    @media (max-width: 768px) {
        display: none;
    }
`;

const ProfileView = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 6px;
    background-color: #000;
    position: absolute;
    right: 1rem;
    top: 3.5rem;
    padding: 0.4rem;
    border-radius: 10px;
    color: white;
    font-weight: 600;

    button {
        border: 0.3px solid white;
        padding: 0.2rem 1rem;
        border-radius: 6px;
        transition: 0.3s ease;

        &:hover {
            background-color: white;
            color: black;
        }
    }
`;

const BurgerAuthItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    img {
        width: 8vw;
        border-radius: 50%;
    }

    button {
        background-color: #000;
        color: white;
        border: 0.3px solid white;
        padding: 0.2rem 1rem;
        border-radius: 6px;
        transition: 0.3s ease;

        &:hover {
            background-color: white;
            color: black;
        }
    }
`;