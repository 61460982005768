import React, {useContext, useState} from 'react'
import styled from 'styled-components'
import {Link, useNavigate} from 'react-router-dom';
import {userContext} from '../context/context';
import {createUserWithEmailAndPassword, updateProfile} from 'firebase/auth';
import {auth, provider, signInWithPopup} from '../lib/firebaseConfig'
import StockAvtar from '../utils/StockAvtar.jpg'
import googlePNG from '../utils/googlePNG.png'


export default function SignUp() {
  
  const [name,setName] = useState('');
  const [email,setEmail] = useState('');
  const [password,setPassword] = useState('');
  const Navigate = useNavigate();
   
  
 


  const userValue = useContext(userContext);




  const onSubmit = async (e) => {
   e.preventDefault();
   try {
     const userCredential = await createUserWithEmailAndPassword(auth,email, password);
     const user = userCredential.user;
    await updateProfile(user, {
      displayName: name
    });
      userValue.setUser({
      "userName":user.displayName,
      "userEmail":user.email,
      "userAccessToken":user.accessToken,
      "userImage":user.photoURL?user.photoURL:StockAvtar
    })
    try {
      const res = await fetch(`${process.env.REACT_APP_URL}/signupAuth`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'  // Specify the content type as JSON
          },
          body: JSON.stringify({
              email: user.email,
              name:user.displayName,
              imageURL:user.photoURL?user.photoURL:StockAvtar,
              token: user.accessToken
          })
      });
      const responseData = await res.json();
      if (res.ok) {
          console.log('Success:', responseData);  
      } else {
          console.error('Error:', responseData); 
      }
  } catch (error) {
      console.error('Fetch error:', error);  
  }

    localStorage.setItem("token",user.accessToken);
     Navigate("/generate");
   } catch (error) {
     const errorCode = error.code;
     const errorMessage = error.message;
     console.log(errorCode, errorMessage);
     Navigate('/')
   }
 };

 const GoogleSignIn = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
   userValue.setUser({
      "userName":user.displayName,
      "userEmail":user.email,
      "userAccessToken":user.accessToken,
      "userImage":user.photoURL?user.photoURL:StockAvtar
    })
    try {
      const res = await fetch(`${process.env.REACT_APP_URL}/signInWithGoogleAuth`, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json'  // Specify the content type as JSON
          },
          body: JSON.stringify({
            email: user.email,
            name:user.displayName,
            imageURL:user.photoURL?user.photoURL:StockAvtar,
            token: user.accessToken
          })
      });
      const responseData = await res.json();
      if (res.ok) {
          console.log('Success:', responseData);  
      } else {
          console.error('Error:', responseData);  
      }
  } catch (error) {
      console.error('Fetch error:', error);  
  }
    localStorage.setItem("token",user.accessToken);
    Navigate('/generate');
  } catch (error) {
    console.error('Error during sign-in:', error);
    Navigate('/')
  }
};
  

  return (
    <Contain>
         <Heading>
          <h3>LET'S GET YOU STARTED</h3>
          <h1>Create an Account</h1>
         </Heading>
         <form>
                         <Name>
                         <p>Your Name</p>
                         <input type="text" placeholder='Johnson Doe' value={name} onChange={e=>setName(e.target.value)}/>
                         </Name>
                      <Email>
                      <p>Email</p>
                      <input type="text" placeholder='johnsondoe@nomail.com' value={email} onChange={e=>setEmail(e.target.value)}/>
                      </Email>
                       <Password>
                       <p>Password</p>
                       <input type="password" placeholder='***************' value={password} onChange={e=>setPassword(e.target.value)}/>
                       </Password>
                         <Started onClick={onSubmit}>GET STARTED</Started>
         </form>
             <Or>
              <span></span>
              <h5>Or</h5>
              <span></span>
             </Or>
              <GoogleSection   onClick={GoogleSignIn}>
                <img src={googlePNG} alt="googleimage"/>
                <Google type='submit'>Log in with Google</Google>
              </GoogleSection>
             <Already>
              <p>Already had an Account? </p>
               <Link style={{fontWeight:'700'}} to={'/login'}>Log In</Link>
             </Already>
    </Contain>
  )
}
const Contain = styled.div`
 min-height: 100vh;
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;


 form{
    display: flex;
 align-items: center;
 flex-direction: column;
 gap: 15px;
 }

  h5{
    margin-top: 4%;
    font-weight: 700;
    font-size: 18px;
  }
`;
const Heading = styled.div`
 min-width: 100vw;
 padding: 0rem 4rem;
  h1{
    font-size: 24px;
    font-weight: 700;
  }
  @media (max-width:768px)
  {
     text-align: center;
    margin-bottom: 5%;
  }
`;

const Name = styled.div`
position: relative;

p{
   padding: 0.3rem;
z-index: 3;
position: absolute;
left: 15px;
top: -16px;
background-color: #fff;
}
input{
   border: 0.3px solid black;
   padding: 0.8rem 1rem;
   min-width: 25vw;
   border-radius: 8px;
}
`;
const Email = styled.div`
position: relative;
 p{
    padding:0px 2px 0px 2px;
 z-index: 3;
 position: absolute;
 left: 15px;
 top: -14px;
 background-color: #fff;
 }
 input{
    border: 0.3px solid gray;
    padding: 0.8rem 1rem;
    min-width: 25vw;
    border-radius: 8px;
 }

`;
const Password = styled.div`
position: relative;
 p{
    padding:0px 2px 0px 2px;
 z-index: 3;
 position: absolute;
 left: 15px;
 top: -14px;
 background-color: #fff;
 }
 input{
    border: 0.3px solid gray;
    padding: 0.8rem 1rem;
    min-width: 25vw;
    border-radius: 8px;
 }

`;
const Started = styled.button`
 background-color: #000;
 color: #fff;
 text-align: center;
 min-width: 25vw;
 padding: 0.8rem 1rem;
 border-radius: 8px;
 font-weight: 500;  
`;
const Or = styled.div`

position: relative;
            min-width: 100vw;
            min-height: 10vh;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            span{
              min-width:25vw;
      background-color: #ded9d9;
      min-height: 0.1vh;
     }
     h5{
       position: absolute;
       z-index: 3;
       padding: 0 2rem 0 2rem;
       background-color: white;
      bottom: 26%;
     }
`;

const GoogleSection = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: row;
 gap: 2rem;
 cursor: pointer;
 min-width:25vw;
 height: 7vh;
 padding-top: 1rem ;
 padding-bottom: 1rem;
 border: 0.2px solid gray;
 border-radius: 12px;
 margin-bottom: 2rem;

 img{
  height: 3vh;
 }
`;

const Google = styled.button`

 border-radius: 8px;
 font-weight: 500;
 font-size: 18px;
`;

const Already = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 4px;
`;
