import React from 'react'
import styled from 'styled-components'
import stock1 from '../../utils/stock1.jpg'
import stock2 from '../../utils/stock2.jpg'
import stock3 from '../../utils/stock3.jpg'
import stock4 from '../../utils/stock4.jpg'
import {Link} from 'react-router-dom'


export default function HeroSection() {
 

  return (
      <Contain>
         {/* <Top>
            <h4>New</h4>
             <p>Create teams in organisation →</p>
         </Top>  */}
         <Heading>Transforming apparel <br />designs with AI-powered <br />inspirations</Heading>
          <MiniHeading>We help apparel brands generate design inspirations  using <br /> software which is easy to use, faster and affordable </MiniHeading>
                  <HoverImage>
                      <ImagePart>
                      <img src={stock1} alt="stock1" />
                      <img src={stock2} alt="stock2" />
                      <img src={stock3} alt="stock3" />
                      <img src={stock4} alt="stock4" />
                      </ImagePart>
                     <p>7 day free trial</p>
                    </HoverImage> 

                    <ButtonPart>
                         {/* <LearnMore >Learn more</LearnMore> */}
                         <Link to='https://calendly.com/abhishekagrawal7707' target='__blank'>
                         <RequestDemo>Request Demo &nbsp; ↗</RequestDemo>
                         </Link>
                    </ButtonPart>
      </Contain>
  )
}

const Contain = styled.div`
 min-height: 100vh;
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 gap: 15px;
`;
// const Top = styled.div`
//  margin-top: 3%;
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  gap: 5px;
//  color: #2929aa;
//  background-color: #e0e2e4;
//  border-radius: 25px;
//   min-height: 6vh;
//   min-width: 20vw;
//   font-size: 16px;
//   font-weight: 500;
//   h4{
//      text-align: center;
//     background-color: #fff;
//     padding: 0.2rem;
//     border-radius: 12px;
//     min-width: 3vw;
//   }
// `;

const Heading = styled.h1`
  font-size: 65px;
  text-align: center;
  font-weight: 600;
   color: #090935;
     line-height: 1.1;
    @media (max-width:768px)
    {
      font-size: 50px;
    } 
`;

const MiniHeading = styled.div`
 text-align: center;
 padding-top: 1rem;
 font-size: 20px;
 font-weight: 500;
 color: #6e6a6a;
`;
const HoverImage = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 8px;
 min-height: 12vh;
`;
const ImagePart = styled.div`
display: flex;
justify-content: center;
align-items: center;
  img{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 2px solid black;
    transition: 0.2s ease-in;
    &:hover{
        margin-bottom: 15px;
    }
  }
`;

const ButtonPart = styled.div`
  gap: 10px;
 padding-top: 1rem;
 display: flex;
 justify-content: space-between;
 align-items: center;
`;
// const LearnMore = styled.button`
//   border: 0.2px solid blue;
//   padding: 0.6rem 1rem;
//    color: blue;
//    font-weight: 500;
//    border-radius:8px;
//    transition: 0.2s ease-out;
//    &:hover{
//     color: white;
//     background-color: blue;
//    }
// `;
const RequestDemo = styled.button`
  padding: 0.6rem 1rem;
     color: white;
   background-color: #000;
   font-weight: 500;
   border-radius:8px;
   transition: 0.2s ease-out;
   &:hover{
    color: black;
    background-color: #fff;
    border: 1px solid;
   }
`;
