import React from 'react';
import styled from 'styled-components';

const SubCategoryDropdown = ({ subCategories, openSubCategory, setOpenSubCategory, open, setOpen, handleSubCategorySelect }) => {
    return (
        <SubCategoryContainer>
            {subCategories.map((subCategory, key) => (
                <SubCategoryItem
                    key={key}
                    onClick={() => {
                        handleSubCategorySelect(subCategory.name);
                    }}
                >
                    {subCategory.name}
                </SubCategoryItem>
            ))}
        </SubCategoryContainer>
    );
};

const SubCategoryContainer = styled.div`
    background-color: white;
    margin-left: 1rem;
    z-index: 3;
    position: absolute;
    left: 100%; /* Position to the right of the main dropdown */
    top: 0;
    min-width: 12vw;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 1px 1px 1px 4px gray;
    padding: 0.4rem 1rem;
    @media (max-width: 768px) {
        position: relative;
        min-width: 15vw;
    }
`;

const SubCategoryItem = styled.div`
    transition: 0.3s ease-in;
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding-left: 0.2rem;

    &:hover {
        background-color: #7894e6;
        border-radius: 4px;
    }
`;

export default SubCategoryDropdown;