import React, {useState} from 'react'
import styled from 'styled-components'

export default function FAQDropdown({title,content}) {
  
     const [open,setOpen] = useState(false);

  return (
     <Contain onClick={e=>setOpen(!open)}>
        <Heading>
            <Title>{title}</Title>
             {
                open===false?
                <svg style={{transition:'0.2s ease-in'}} width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 16L6 10H18L12 16Z" fill="black"/>
                </svg>:
            <svg style={{transition:'0.2s ease-in',transform: 'rotate(180deg)'}} width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 16L6 10H18L12 16Z" fill="black"/>
            </svg>
             }

        </Heading>
        {open===false?<></>:<ContentPart>{content}</ContentPart>}
     </Contain>
  )
}

const Contain = styled.div`
 display:flex;
 justify-content: center;
 flex-direction: column;
 text-align: justify;
 min-width: 60vw;
 min-height: 12vh;
 padding: 0.8rem;
 border-radius: 15px;
 transition: 0.3s ease-in;
 &:hover{
    background-color:aliceblue;
 }
`;
const Heading = styled.div`
cursor: pointer;
 display: flex;
 justify-content: space-between;
 align-items: center;
`;
const Title = styled.div`
 font-size: 20px;
 font-weight: 600;
 color: #033d61;
`;
const ContentPart = styled.div`
 min-height: 4vh;
 margin-top:8px;
 transition: 0.3s ease-in;
 font-size: 18px;
 font-weight: 600;
 color: gray;
 cursor: pointer;
`;


