import React from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom';
import FAQDropdown from './FAQDropdown';

export default function FAQ() {

    const data = [
        {
            "title": "Does this product offer a trial period?",
            "content": "Yes, we offer a free trial period for 7 days and post that you can join any of plan based on your requirements."
        }, {
            "title": "What payment methods do you offer?",
            "content": "We offer online payment methods like Razorpay and other UPI platforms."
        }, {
            "title": "How much does a subscription cost?",
            "content": "We offer a value based subscription model ,to choose what fits right you can go to Pricing Page."
        },

        {
            "title": "Is there a free trial?",
            "content": "We offer a 7 day free trial to use the product and then you can subscribe to the platform."
        }, {
            "title": "How can we reach out to you?",
            "content": <>We offer a 24 *7 customer support (<Link to='/contactus'>contact us</Link>).</>
        },]

    return (<Contain>
        <Faq>FAQ</Faq>
        <Heading>We’ve got you covered</Heading>
        <Dropdown>
            {data.map((x, key) => (<FAQDropdown key={key} title={x.title} content={x.content}/>))}
        </Dropdown>
    </Contain>)
}
const Contain = styled.div`
    margin-top: 3%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
const Faq = styled.div`
    font-size: 32px;
    font-weight: 600;
    background-color: aliceblue;
    padding: 0.1rem 0.8rem;
    border-radius: 10px;
`;
const Heading = styled.div`
    margin-top: 0.4rem;
    font-size: 54px;
    font-weight: 600;
    color: #023e73;
`;
const Dropdown = styled.div`
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 45vw;
    gap: 5px;
`;