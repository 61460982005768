import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Navbar from "../components/Navbar";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
`;

const ImageGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    width: 100%;
    padding: 1rem;
`;

const ImageItem = styled.div`
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 2px 2px 6px 2px gray;

    img {
        width: 100%;
        height: auto;
        display: block;
    }

    button {
        position: absolute;
        bottom: 10px;
        right: 10px;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        cursor: pointer;
        background-color: #5F5CE8;
        color: white;
        border: none;
        border-radius: 5px;
    }
`;

const ImageHistory = () => {
    const [images, setImages] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const observer = useRef();
    const sentinel = useRef(null);

    useEffect(() => {
        fetchImages(page);
    }, [page]);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0
        };

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && !loading) {
                setPage((prevPage) => prevPage + 1);
            }
        }, options);

        const currentSentinel = sentinel.current;
        if (observer.current && currentSentinel) {
            observer.current.observe(currentSentinel);
        }

        return () => {
            if (observer.current && currentSentinel) {
                observer.current.unobserve(currentSentinel);
            }
        };
    }, [loading]);

    const fetchImages = async (page) => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/api/forward/images?page=${page}&path=history`);
            const data = await response.json();
            setImages((prevImages) => [...prevImages, ...data.images]);
        } catch (error) {
            console.error('Error fetching images:', error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <Container>
            <Navbar />
            <UpperInput>
                <h1>History !!</h1>
            </UpperInput>
            <ImageGrid>
                {images.map((image, index) => (
                    <ImageItem key={index}>
                        <img src={image} alt={"notfound"} />
                        {/*<button onClick={() => handleDownload(image)}>||</button>*/}
                    </ImageItem>
                ))}
                <div ref={sentinel} style={{ height: '1px' }}></div>
            </ImageGrid>
            {loading && <p>Loading...</p>}
        </Container>
    );
};

const UpperInput = styled.div`
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    h1 {
        font-weight: 700;
        font-size: 44px;
        text-align: center;
        background: #5F5CE8;
        background: linear-gradient(to right, #5F5CE8 0%, #221A1A 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 2rem;
    }
`;

export default ImageHistory;