import React from 'react'
import styled from 'styled-components'
import Navbar from '../components/Navbar';


export default function Page404() {
  return (
    <Contain>
       <Navbar/>
        <Main>404! Page Not Found </Main>
  
    </Contain>
  )
}

const Contain = styled.div``;
const Main = styled.div`
 min-height: 45vh;
 text-align: center;
 display: flex;
 justify-content: center;
 align-items: center;
 font-size: 34px;
 font-weight: 700;
`;
