import React, {useRef, useState, useEffect} from 'react';
import styled from 'styled-components';
import fullscreen from '../../utils/fullscreen.svg';

export default function FullScreenComp({url}) {
    const [isVisible, setIsVisible] = useState(false);
    const imageRef = useRef(null);

    useEffect(() => {
        const handleFullscreenChange = () => {
            if (!document.fullscreenElement) {
                setIsVisible(false);
            }
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    useEffect(() => {
        if (isVisible && imageRef.current) {
            if (imageRef.current.requestFullscreen) {
                imageRef.current.requestFullscreen();
            } else if (imageRef.current.webkitRequestFullscreen) { /* Safari */
                imageRef.current.webkitRequestFullscreen();
            } else if (imageRef.current.msRequestFullscreen) { /* IE11 */
                imageRef.current.msRequestFullscreen();
            }
        }
    }, [isVisible]);

    const enterFullScreen = () => {
        setIsVisible(true);
    };

    return (
        <ImageFramerFull>
            {isVisible && <img ref={imageRef} src={url} alt="results images"/>}
            <FullScreenButton onClick={enterFullScreen}><img src={fullscreen} alt=""/></FullScreenButton>
        </ImageFramerFull>
    );
}


const ImageFramerFull = styled.div`
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 4px;
    right: 0.8rem;
    transition: transform 0.1s ease-in-out;

    &:hover {
        transform: scale(1.5);
        color: blue; /* Change to blue on hover */
    }

    svg {
        transition: fill 0.3s ease-in;
    }

    &:hover svg path {
        fill: blue; /* Change to blue on hover */
    }

    img {
        width: 2.1vw;
        height: 30px;
        opacity: 90%;
    }

    @media (max-width: 768px) {
        width: 2vw;
    }
`;


const FullScreenButton = styled.div`
    width: 100%;
    transition: 0.3s ease;
    z-index: 1;

    svg {
        transition: fill 0.3s ease-in;
    }
`;