import React, {useState} from 'react';
import styled from 'styled-components';
import Navbar from "../components/Navbar";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
`;

const UploadSection = styled.div`
    margin-bottom: 2rem;
    padding: 2rem;
    border: 2px dashed #3ebfee;
    border-radius: 10px;
    background-color: #f9f9f9;
    text-align: center;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #e0f7fa;
    }

    input {
        display: none;
    }

    label {
        display: inline-block;
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 5px;
        background-color: #3ebfee;
        color: white;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #2f46b7;
        }
    }

    img {
        max-width: 30%;
        max-height: 300px; /* Adjust this value as needed */
        border-radius: 10px;
        box-shadow: 2px 2px 6px 2px gray;
        margin-top: 1rem;
    }
`;

const Options = styled.div`
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;

    button {
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 5px;
        background-color: #3ebfee;
        color: white;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #2f46b7;
        }
    }
`;

const GeneratedImages = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
`;

const ImageOptions = styled.div`
    display: none;
    position: absolute;
    bottom: 10px;
    left: 10px;
    gap: 0.5rem;

    svg {
        cursor: pointer;
        transition: 0.3s ease-in;
        width: 24px;
        height: 24px;

        &:hover {
            transform: scale(1.2);
        }

        path {
            transition: fill 0.3s ease-in;
        }
    }
`;

const ImageContainer = styled.div`
    position: relative;
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 2px 2px 6px 2px gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 20%;
    height: auto;

    &:hover ${ImageOptions} {
        display: flex;
    }
`;

const SVGlike = styled.svg`
    &:hover path {
        fill: limegreen;
    }

    path {
        stroke: white; /* Add white outline */
        stroke-width: 1; /* Adjust the width as needed */
    }
`;

const SVGdislike = styled.svg`
    &:hover path {
        fill: red;
    }

    path {
        stroke: white; /* Add white outline */
        stroke-width: 1; /* Adjust the width as needed */
    }
`;

const SVGdownload = styled.svg`
    &:hover path {
        fill: black;
    }

    transition: 0.3s ease-in;


    //path {
    //    stroke: white; /* Add white outline */
    //    stroke-width: 2; /* Adjust the width as needed */
    //}
`;

const MockupUpload = () => {
    const [uploadedImage, setUploadedImage] = useState(null);
    const [generatedImages, setGeneratedImages] = useState([]);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setUploadedImage(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const handleDownload = async (url) => {

        const link = document.createElement('a');
        link.href = url;
        link.download = 'image.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    };

    const handleOptionClick = async (option) => {
        if (!uploadedImage) return;

        // Clear generated images before making the backend call
        setGeneratedImages([]);

        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/createTshirtMockup`, {
                method: 'POST', headers: {
                    'Content-Type': 'application/json',
                }, body: JSON.stringify({metaData: uploadedImage, mockup_category: option}),
            });
            const data = await response.json();
            const images = data.merged_image.map(item => `data:image/png;base64,${item.metaData}`); // Convert base64 to data URL
            setGeneratedImages(images || []); // Ensure images is an array
        } catch (error) {
            console.error('Error generating mockups:', error);
        }
    };

    return (<Container>
            <Navbar/>
            <UpperInput>
                <h1>Mockup Templates !!</h1>
            </UpperInput>
            <UploadSection>
                <input type="file" accept="image/*" id="file-upload" onChange={handleImageUpload}/>
                <label htmlFor="file-upload">Upload Image</label>
                {uploadedImage && <img src={uploadedImage} alt="Uploaded"/>}
            </UploadSection>
            <Options>
                <button onClick={() => handleOptionClick('Oversized')}>Oversized</button>
                <button onClick={() => handleOptionClick('Roundneck')}>Roundneck</button>
                <button onClick={() => handleOptionClick('Slimfit')}>Slimfit</button>
            </Options>
            <GeneratedImages>
                {generatedImages.map((image, index) => (<ImageContainer key={index}>
                        <img src={image} alt={`Generated ${index}`}/>
                        <ImageOptions>

                            <SVGlike
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2 21H6V9H2V21ZM23 10.9999C23 9.89995 22.1 8.99995 21 8.99995H14.72L15.64 4.63995L15.66 4.33995C15.66 3.74995 15.39 3.19995 14.96 2.84995L14.28 2.27995L7.6 8.53995C7.22 8.87995 7 9.40995 7 9.99995V18.9999C7 20.1 7.9 20.9999 9 20.9999H18C18.71 20.9999 19.31 20.6299 19.66 20.0699L22.86 14.4799C22.95 14.3299 23 14.1599 23 13.9999V10.9999Z"
                                    fill="black"
                                />
                            </SVGlike>

                            <SVGdislike
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M22 3H18V15H22V3ZM2 14.9999C2 16.0999 2.9 16.9999 4 16.9999H10.28L9.36 21.3599L9.34 21.6599C9.34 22.2499 9.61 22.7999 10.04 23.1499L10.72 23.7199L17.4 17.4599C17.78 17.1199 18 16.5899 18 15.9999V6.99995C18 5.89995 17.1 4.99995 16 4.99995H7C6.29 4.99995 5.69 5.36995 5.34 5.92995L2.14 11.5199C2.05 11.6699 2 11.8399 2 11.9999V14.9999Z"
                                    fill="black"
                                />
                            </SVGdislike>

                            <SVGdownload
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() => handleDownload(image)}
                            >
                                <path
                                    d="M5 20H19V18H5V20ZM12 3L12 14M12 14L8 10M12 14L16 10"
                                    stroke="black"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </SVGdownload>

                        </ImageOptions>
                    </ImageContainer>))}
            </GeneratedImages>
        </Container>);
};

const UpperInput = styled.div`
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    h1 {
        font-weight: 700;
        font-size: 44px;
        text-align: center;
        background: #5F5CE8;
        background: linear-gradient(to right, #5F5CE8 0%, #221A1A 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 2rem;
    }
`;
export default MockupUpload;