import React, {useRef} from 'react'
import styled from 'styled-components'
import fullscreen from '../../utils/fullscreen.svg'

export default function MockUpCard({url}) {

    const imageRef = useRef(null);
    const enterFullScreen = () => {
        if (imageRef.current.requestFullscreen) {
            imageRef.current.requestFullscreen();
        } else if (imageRef.current.webkitRequestFullscreen) { /* Safari */
            imageRef.current.webkitRequestFullscreen();
        } else if (imageRef.current.msRequestFullscreen) { /* IE11 */
            imageRef.current.msRequestFullscreen();
        }
    };

    const downloadImage = () => {
        try {
            const link = document.createElement('a');
            link.href = url;
            link.download = 'image.png'; // You can change the file name and extension as needed
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading the image:', error);
        }
    };


    return (<ImageFramer>
        <img ref={imageRef} src={url} alt="results images"/>
        <ActionButtons>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2 21H6V9H2V21ZM23 10.9999C23 9.89995 22.1 8.99995 21 8.99995H14.72L15.64 4.63995L15.66 4.33995C15.66 3.74995 15.39 3.19995 14.96 2.84995L14.28 2.27995L7.6 8.53995C7.22 8.87995 7 9.40995 7 9.99995V18.9999C7 20.1 7.9 20.9999 9 20.9999H18C18.71 20.9999 19.31 20.6299 19.66 20.0699L22.86 14.4799C22.95 14.3299 23 14.1599 23 13.9999V10.9999Z"
                    fill="black"/>
            </svg>

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M22 3H18V15H22V3ZM2 14.9999C2 16.0999 2.9 16.9999 4 16.9999H10.28L9.36 21.3599L9.34 21.6599C9.34 22.2499 9.61 22.7999 10.04 23.1499L10.72 23.7199L17.4 17.4599C17.78 17.1199 18 16.5899 18 15.9999V6.99995C18 5.89995 17.1 4.99995 16 4.99995H7C6.29 4.99995 5.69 5.36995 5.34 5.92995L2.14 11.5199C2.05 11.6699 2 11.8399 2 11.9999V14.9999Z"
                    fill="black"/>
            </svg>

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                 onClick={downloadImage}>
                <path d="M5 20H19V18H5V20ZM12 3L12 14M12 14L8 10M12 14L16 10" stroke="black" strokeWidth="2"
                      strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

            <FullScreen onClick={enterFullScreen}><img src={fullscreen} alt=""/></FullScreen>
        </ActionButtons>

    </ImageFramer>)
}

const ImageFramer = styled.div`
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 15px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 5px 5px 10px 5px gray;

    @media (max-width: 768px) {
        width: 100%;
    }

    img {
        width: 90%;
        border-radius: 10px;
    }
`;
const ActionButtons = styled.div`
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    opacity: 0%;
    transition: 0.15s ease-out;

    &:hover {
        background-color: lightgray;
        opacity: 80%;
        border-radius: 10px;
    }

    svg {
        width: 50%;
        height: 50%;

        &:hover {
            background-color: lightblue;
        }

        cursor: pointer;
    }

`;

const FullScreen = styled.div`
    position: absolute;
    bottom: 4px;
    right: 0.4rem;
    border-radius: 50%;
    transition: 0.3s ease;

    &:hover {
        background-color: aliceblue
    }
`;

